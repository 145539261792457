import React from 'react';

import { Outer, Inner, P, Box, DocPage } from '../components/styled';
import DocPageLayout from '../components/layout/DocPageLayout';
import termsBackground from '../images/bg_terms.svg';

// prettier-ignore
const TermsPage = () => (
  <DocPageLayout title="Terms of Use" subtitle="Posting Date: November 4, 2018" backgroundSrc={termsBackground}>
    <Outer>
      <Inner pt={80}>
        <DocPage>
          <Box mb={40}>
            <P pb={20}>You are entering a legally binding agreement.</P>
            <P>
              Please read the Terms of Use carefully, as your use of the https://sprintlio.com/ website (the “<strong>Site</strong>”) constitutes your acceptance of these legally binding Terms of Use (provided that if/as required, you have also confirmed your clear, express and affirmative consent in connection with how we collect and process your Personal Information, as defined in the Privacy Policy). Do not use the Site if you are unwilling or unable to be bound by the Terms of Use. The Terms of Use include and incorporate the <a href="/privacy">Privacy Policy</a>.
            </P>
          </Box>
          <h2>1. Definitions</h2>
          <p>
            A. “<strong>Content</strong>” means personal data or information, text, images, photos, audio, video, and all other forms of data or communication. “<strong>Your Content</strong>” means Content that you submit or transmit to or through the Site, such as invitations, and information that you display as part of your account profile. “<strong>User Content</strong>” means Content that users submit or transmit to or through the Site. “<strong>Sprintlio Content</strong>” means Content that we create and make available on the Site. “<strong>Third Party Content</strong>” means Content that is made available on the Site by parties other than you or Sprintlio. “<strong>Site Content</strong>” means all of the Content that is made available on the Site, including Your Content, User Content, Third Party Content, and Sprintlio Content.
          </p>
          <p>
            B. “<strong>Site</strong>” includes without limitation the https://sprintlio.com/ website, software, hardware, tangible and intangible assets, property managed, licensed or used by Sprintlio at its principal place of business for operating and making available the Site to Internet users, registered accounts, Site Content, and information posted and accessible therein.{' '}
          </p>
          <p>
            C. A “<strong>user</strong>” is someone who accesses, browses or in any way uses the Site. The terms “you” and “your” refer to you, as a user of the Site. The terms “<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”, and “<strong>Sprintlio</strong>” refer to Sprintlio Inc. and its affiliates and agents.
          </p>

          <h2>2. Purpose, Scope and Intent</h2>
          <p>
            2.1. Sprintlio has created and established this Site to provide subscriptions for software that enables agile and distributed teams to elevate their retrospectives (the “<strong>Purpose</strong>”).
          </p>
          <p>2.2. You agree that by registering for an Account (as defined herein) or by using the Site or by providing other information as part of the services provided by Sprintlio related to the Purpose you are entering into a legally binding agreement with Sprintlio based upon the terms of the Terms of Use, including the Privacy Policy, as may be subsequently amended, revised, updated or replaced, which are hereby incorporated by reference (provided that you have also confirmed your clear, express and affirmative consent in connection with how we collect and process your Personal Information). If you are using the Site on behalf of an organization, you are not only individually bound by these Terms of Use, but also agreeing to these Terms of Use on behalf of your organization and representing and warrantying that you have the authority to bind that organization to these Terms of Use. </p>

          <h2>3. Payments</h2>
          <p>3.1. With respect to any purchase made on or through the Site, you agree that (a) you will not use an invalid or unauthorized credit or debit card or other payment method, (b) you are solely responsible for identifying and selecting the product or service you wish to purchase, (c) your payment will be charged to the credit or debit card or other payment method you use through our authorized third party service provider, Stripe (“Payment Partner”), and (d) by submitting your credit or debit card information through the Site, you expressly consent to the sharing of this information with our Payment Partner. We do not save your credit card information in our servers and only retain the last four digits of your credit card or debit card information. Our authorized third party service providers currently only accept the following payment methods: Visa, Mastercard and American Express. This list may change from time to time to reflect our good faith efforts at accepting commonly used credit cards.</p>
          <p>3.2. Sprintlio reserves the right to reject, in its full discretion, any and all orders or purchases made on the Site.</p>

          <h2>4. Permitted Use and Your Obligations</h2>
          <p>4.1. Your use of the Site shall indicate your consent to be legally bound by, and comply with, all of the terms and conditions contained herein (provided that you have also confirmed your clear, express and affirmative consent in connection with how we handle your Personal Information). By using the Site, you represent and warrant that you have read the terms and conditions contained herein and that you have full right, power, and authority to execute, deliver and fully perform your obligations under the Terms of Use. At its sole discretion, Sprintlio may periodically modify and supplement the Terms of Use at any time and the notice provided to you or the posting of the Terms of Use on the Site, will be the updating of the Terms of Use. You are responsible for regularly checking the Terms of Use for revision. Non-material changes will take effect immediately. Material changes will take effect 30 days after their posting. The posting date of each document will be set out at the end of the document. Any use by you of the Site after such effective date signifies your consent and agreement to be legally bound by, and comply with, all of the modified Terms of Use. Despite the foregoing, to the extent that any such revisions relate to the collection and/or processing of your Personal Information, such revisions will only take effect following your clear, express, affirmative consent thereto.</p>
          <p>
            4.2. You may be required to register and create an account (“<strong>Account</strong>”) with the Site in order to access certain features of the Site. Information gathered through the registration process and information related to your Account will be subject to the Terms of Use, inclusive of the Privacy Policy.
          </p>
          <p>
            4.3. You represent and warrant that you are at least the Minimum Age. “<strong>Minimum Age</strong>” means the greater of (a) 18 years of age or (b) such age as applicable law requires that you must be in order for us to lawfully provide the services provided under this Site to you (including the collection, processing storage and use of your information in accordance with our Privacy Policy). Notwithstanding the foregoing, this Site is not intended for or directed at children under the age of 18 years.
          </p>
          <p>4.4. You agree that you are only authorized to visit, view, and retain a copy of pages of this Site for your own personal use and not for any commercial purpose.</p>
          <p>4.5. You agree that you will not violate any rights of Sprintlio, third parties, or of other users, including without limitation intellectual property rights such as copyright or trademark rights.</p>
          <p>
            4.6. You are under no obligation to provide any ideas, suggestions, documents or proposals (“<strong>Contributions</strong>”) to us. You agree that in the event you provide any Contributions to us whether through our feedback webpages or otherwise: (a) your Contributions do not contain any information which is confidential or proprietary; (b) we are under no obligation to treat the Contributions as confidential or proprietary; (c) we may have developed information similar to the Contributions prior to your providing the Contributions to us; (d) we shall be entitled to treat the Contributions as we choose in our sole and absolute discretion, including without limitation disclosing such all or part of the Contributions to third parties or to the public; and (e) you irrevocably provide us with a non-exclusive, fully paid, world-wide license to exploit the Contributions without any form of compensation, reimbursement or recognition whatsoever.
          </p>
          <p>4.7. You agree that you shall not duplicate, download, publish, modify, adapt, create derivative works, appropriate, reproduce, or otherwise distribute, sell, trade, or in any way exploit the materials of this Site or any use, or for any purpose other than as described in the Terms of Use.</p>
          <p>4.8. You agree to provide at your cost all equipment, software, mobile access, and Internet access necessary to use the Site.</p>
          <p>4.9. You agree to comply with all applicable laws and with the Terms of Use, as may be amended from time to time with or without advance notice.</p>
          <p>4.10. If you believe that you are entitled or obligated to act contrary to the Terms of Use under any mandatory law, you agree to provide us with detailed and substantiated explanation of your reasons in writing at least 30 days before you act contrary to the Terms of Use to allow us to determine whether we may, at our sole discretion, provide an alternative remedy for the situation, though we are under no obligation to do so, or proceed to the termination of your registration and right to use of the Site.</p>
          <p>4.11. You agree that we are under no obligation to notify you of any changes to the Terms of Use and that at our sole discretion we may choose to notify you by any means of our choosing, including without limitation by posting the relevant changes or amended Terms of Use in the Site, placing a banner notice on the Site, sending you an e-mail, written information or calling you using information you provided to us. You expressly agree that (a) in the event we notify you of any change to the Terms of Use, such notification shall not be deemed to create an on-going obligation to provide further notification and (b) we shall have no liability whatsoever related to notifying or not notifying you of such changes to the Terms of Use.</p>
          <p>
            4.12. You may opt in to the receipt of commercial electronic messages (“<strong>CEM</strong>”) related to the Purpose, this Site and any other permitted use under our Privacy Policy. These CEM may be sent to you directly by Sprintlio or on Sprintlio’s behalf by third parties. At any time you may withdraw your consent to receive such CEM (opt-out) by contacting Sprintlio by any means Sprintlio uses to contact you and stating that you withdraw your consent, subject to applicable laws. Even where your consent is withdrawn, Sprintlio may still contact you where such contact is deemed necessary for the proper management of your Account or your use of the Site or where you subsequently opt-in, to the extent permitted under applicable laws.
          </p>

          <h2>5. Your Right to Use and Access the Site – A Limited License</h2>
          <p>5.1. On the condition that you comply with all your obligations under these Terms of Use, we grant you a limited, revocable, non-exclusive, non-assignable, non-sublicenseable license and right to access the Site through a generally available web browser (but not through scraping, spidering, crawling or other technology or software used to access data without the express written consent of Sprintlio), to view information and use the Site in accordance with the Terms of Use.</p>
          <p>
            5.2. You may create a link (the “<strong>Hyperlink</strong>”) to the Site provided that:
          </p>
          <ul>
            <li>You do not replicate any of the Content of the Site;</li>
            <li>You do not create a frame or any other bordered environment around the Site or any of the Content contained on the Site;</li>
            <li>The Hyperlink shall not imply any endorsement of any product or services or party;</li>
            <li>The website linking to the Site does not contain material prohibited under Section 6.2; and</li>
            <li>The website linking to the Site does not contain any information, content or material that would interfere with the operation of the Site. </li>
            <li>Sprintlio may withdraw your right to link to the Site at any time in its sole and absolute discretion, in which case you agree to forthwith permanently remove the Hyperlink.</li>
          </ul>
          <p>5.3. Any other use of the Site contrary to the Terms of Use, including without limitation use contrary to the Purpose, is strictly prohibited and a violation of the Terms of Use.</p>
          <p>5.4. We reserve all rights not expressly granted in these Terms of Use, including without limitation title, ownership, intellectual property rights and all other rights and interest in Sprintlio, the Site and all related items, including any and all copies made of the Site. You do not acquire or have any ownership, license or other proprietary interest in the Site, Content or any information contained on or provided by the Site.</p>

          <h2>6. Prohibited Use of the Site</h2>
          <p>6.1. You shall not use the Site in any way that would interfere with its operation nor submit any information, material or content to the Site that violates any law or the Terms of Use.</p>
          <p>6.2. You agree that Content you will post, upload, publish, transmit or otherwise distribute on the Site will not:</p>
          <ul>
            <li>Be misleading in fact to individuals reading or viewing your User Content;</li>
            <li>Promote any illegal activity;</li>
            <li>Be for an individual other than yourself or be on behalf of a company or other legal entity for which you have not been properly authorized to provide such Content;</li>
            <li>Be offensive, harmful and/or abusive, including but not limited to, contain images depicting or language containing expletives or profanities, obscenities, harassment, vulgarities, sexually explicit material or that promotes bigotry or discrimination against protected classes (e.g., racist/discriminatory speech or imagery);</li>
            <li>Accuse others of illegal activity, or describe physical confrontations and/or sexual harassment;</li>
            <li>Be of a nature that does not address the goods and services, atmosphere, or other attributes of the business or have no qualitative value (including, but not limited to, spam, chain or other mass messaging);</li>
            <li>Violate any third-party right, including, but not limited to, right of privacy, right of publicity, copyright, trademark, patent, trade secret, or any other intellectual property or proprietary rights;</li>
            <li>Be inappropriate based on the applicable subject matter;</li>
            <li>Use the Site for unauthorized advertising or promotional materials, “junk mail,” “spam,” surveys, contests, “chain letters,” “pyramid schemes,” or other form of solicitation materials;</li>
            <li>Contain material that, in our sole discretion, violates the standards of good taste or the standards of this Site;</li>
            <li>Contain material that is illegal, or that violates any federal, state, or local law or regulation;</li>
            <li>Contain language or images intended to impersonate other individuals (including names of other individuals) or offensive or inappropriate user names or signatures;</li>
            <li>Even if it is legal where you are located, provide information that promotes or facilitates escort services or prostitution; and</li>
            <li>Contain material that is encrypted or that contains viruses, Trojan horses, worms, time bombs, cancelbots or other computer programming routines that are intended to damage, interfere with, intercept or appropriate any system, data or personal information.</li>
          </ul>
          <p>6.3. By uploading or submitting any User Content, you automatically grant (or automatically warrant that the owner of the rights to such User Content has expressly granted) Sprintlio a perpetual, royalty-free, irrevocable, non-exclusive right and license throughout the world to use, reproduce, modify, adapt, create derivative works from, publish, translate, license, transmit, distribute and otherwise exploit any or all portions of such User Content in any manner and media and by means of any technology now known or hereafter developed. Due to the automatic nature of the Site, you acknowledge that moral rights on any Content posted, uploaded or submitted by you may not be disclosed properly and therefore you hereby irrevocably waive all moral rights in any such User Content posted, uploaded or submitted by you, including without limitation the right to exercise any claim or action against Sprintlio due to such foreseeable effect. You acknowledge and agree that Sprintlio may preserve any Content, transmittal or communication by you with Sprintlio through the Site or any service offered on or through the Site, and may also disclose such data if required to do so by law or Sprintlio determines that such preservation or disclosure is reasonably necessary to (a) comply with legal process, (b) enforce these Terms of Use, (c) respond to claims that any such data violates the rights of others, or (d) protect the rights, property or personal safety of Sprintlio, its employees, Users of or visitors to the Site, and the public.</p>
          <p>6.4. Sprintlio is under no obligation to review any User Content submitted, posted or otherwise displayed on the Site and assumes no responsibility or liability whatsoever relating to any such User Content. You may not imply that any User Content is any way sponsored or endorsed by Sprintlio, nor that Sprintlio has reviewed, is aware of or approved, or taken any decision or action on any Content submitted, posted or otherwise displayed on the Site.</p>
          <p>6.5. You will not reverse engineer or attempt to derive the source code of the Site. You will not send automated queries to the Site without the prior written approval of us. You will not decompile, reverse engineer or otherwise manipulate or work with the software or software architecture of the Site. You will not use any robot or any other automatic device and you will not use any manual process to monitor or to copy any information in the Site for any unauthorized purpose. You will not use any automatic device, software, or other routine to interfere with or attempt to interfere with the functioning of the Site. You will not interfere with the operation of the Site or any other party’s use of the Site. Any User violating any Terms of Use, shall be responsible for damages generated, to Sprintlio, to other Users or to third parties, including court and legal fees and expenses.</p>
          <p>6.6. You will not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or networks connected to the Site or to any Sprintlio server, or to any of the services offered on or through the Site, by hacking, password “mining” or any other illegitimate means. You will not probe, scan, or test the vulnerability of the Site or any network connected to the Site, nor breach the security or authentication measures on the Site or any network connected to the Site. You will not reverse look-up, trace, or seek to trace any information on any other user of or visitor to the Site, to its source, or exploit the Site or any service or information made available or offered by or through the Site, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than your own information, as provided for by the Site.</p>
          <p>6.7. You agree that you will use the Site in a reasonable manner as it is customary to provide business to business communication in accordance to similar formats, and particularly, will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Site or Sprintlio’s systems or networks, or any systems or networks connected to the Site or to Sprintlio. You agree not to use any device, software, or routine to interfere or attempt to interfere with the proper working of the Site or any transaction being conducted on the Site, or with any other person’s use of the Site.</p>
          <p>6.8. You will not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal you send to Sprintlio on or through the Site or any service offered on or through the Site. You will not pretend that you are, or that you represent, someone else, or impersonate any other individual or entity.</p>
          <p>6.9. Sprintlio reserves the right, but not the obligation, to refuse to post or remove any User Content at our sole and absolute discretion.</p>

          <h2>7. Site Availability</h2>
          <p>7.1. From time to time and without prior notice to you, we may change, expand and improve the Site. We may also, at any time, cease to continue operating part or all of the Site or selectively disable certain aspects or portions of the Site. Any modification or elimination of the Site will be done in our sole and absolute discretion and without an ongoing obligation or liability to you, and your use of the Site does not entitle you to the continued provision or availability of the Site.</p>

          <h2>8. Disclosure of User Information</h2>
          <p>8.1. You acknowledge, consent and agree that we may access, preserve and disclose your registration and any other information you provide in accordance with the terms of the Privacy Policy if required to do so by law or in a good faith belief that such access, preservation or disclosure is reasonably necessary in our opinion to: (a) comply with legal process, including without limitation civil and criminal subpoenas, court orders or other compulsory disclosures; (b) enforce the Terms of Use; (c) respond to claims of a violation of the rights of third parties; (d) respond to customer service inquiries; or (e) protect the rights, property, or personal safety of Sprintlio, users or the public. Disclosures of user information to third parties other than those required to provide customer support, administer this agreement, or comply with legal requirements are addressed in the Privacy Policy.</p>

          <h2>9. Intellectual Property Notices</h2>
          <p>9.1. The Site contains and includes the copyrights and other intellectual property rights of Sprintlio and except for the limited license granted to you in Section 5.1, we reserve all of our intellectual property and other rights in the Site, the Purpose, and all trademarks, service marks, graphics, logos and domain names used in connection with the Site and the Purpose are trademarks or registered trademarks of or licensed to Sprintlio in Canada and/or other countries. Other trademarks and logos used in connection with the Site and the Purpose may be licensed trademarks or owned by their respective owners. The Terms of Use does not grant you any right or license with respect to any such trademarks and logos.</p>
          <p>9.2. You agree to abide by all copyright notices, information and restrictions contained in any Content on, or accessed through, the Site, and maintain such notices in the Content.</p>
          <p>9.3. You agree not to use any trademark displayed on the Site without the express written permission of the applicable owner or authorized agent of the owner of the trademark.</p>

          <h2>10. How You May Use Site Content</h2>
          <p>10.1. By using the Site, you agree that you will not copy, reproduce, alter, modify, create derivative works from, rent, lease, loan, sell, distribute or publicly display any of the Site Content (except for your own personal, non-commercial use) from the Site. In addition, you will not use the Site Content for any unauthorized non-commercial marketing and promotional campaigns, target or mass solicitation campaigns or political campaigning.</p>
          <p>10.2. You are prohibited from data mining, scraping, crawling, or using any robot, other automatic device, script, technology or processes that send automated queries to the Site, or from using other similar methods and tools to gather or extract data from the Site.</p>
          <p>10.3. You may not use the Site to compile data (or any other portion of the Site Content), in a manner that is used or usable by a competitive listing product or service. You may not use any device, software or routine to interfere or attempt to interfere with the proper functioning, operation, usage and display of the Sites by any other authorized users and third parties.</p>
          <p>10.4. You are prohibited from modifying or obscuring the manner in which the Site is displayed or used, including framing, scraping or any other technique that would alter the visual display of the Site or Site Content. You may not obscure advertisements displayed as part of the Site, nor modify the advertisements and listings in a way that is unauthorized. Unless expressly authorized by Sprintlio in our sole and absolute discretion, you may not link to the Site (including deep linking to a specific portion of the Site), except as provided for herein. You are not permitted to script searches or search results of the Site in a manner that results in the automated display of Site Content on a third party website.</p>

          <h2>11. Registered User Accounts</h2>
          <p>
            11.1. You may be required to register and create an Account in order to access certain features of the Site. Information gathered through the registration process and information related to your Account, as well as the creation and maintenance of your Account, will be subject to the Terms of Use, inclusive of the Privacy Policy. Sprintlio may terminate or suspend your Account if you are in violation of these Terms of Use or if Sprintlio reasonably believes your use of your Account or the Site could cause us or other users to incur cost, damage, expense, or liability of any kind. You may terminate your Account at any time by contacting us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>.
          </p>
          <p>11.2. During registration for an Account, you will select a password and enter your e-mail address. You understand and agree that you are solely responsible for maintaining the confidentiality of your account including your password, and are fully responsible for all activities that occur under your Account. You agree to (a) immediately notify Sprintlio of any unauthorized use of your password or Account or any other breach of security related thereto, and (b) log out from your Account at the end of each session. In addition to any other limitations on liability contained in the Terms of Use, you agree that Sprintlio will not be liable for any loss or damage arising from your failure to comply with this Section.</p>

          <h2>12. Privacy Policy</h2>
          <p>
            12.1. Sprintlio’s Privacy Policy is a binding and integral part of the Terms of Use. The Privacy Policy can be accessed <a href="/privacy">here</a>. We have a long-standing commitment to safeguarding the information you entrust us with, including any personal information we collect and/or process. We will use this personal information for the purposes described further in the Privacy Policy. If you want to discontinue communication with us, or have your personal information removed from our systems, please contact us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>.
          </p>

          <h2>13. Termination</h2>
          <p>13.1. You are under no obligation to use or continue to use the Site and may at any time temporarily or permanently cease using the Site.</p>
          <p>13.2. We may, at any time in our sole discretion, and without any responsibility on or liability to Sprintlio, (a) suspend or terminate your access to and use of the Site or any of its features in response to a breach of the Terms of Use, or for any other reason; (b) move, edit, delete or destroy any materials that you provide or deliver to the Site; (c) access, preserve, or disclose any materials that you provide or deliver to the Site; or (d) take any other remedial action available at law in response to a breach of the Terms of Use.</p>

          <h2>14. Indemnification</h2>
          <p>14.1. You agree to indemnify and hold Sprintlio, its members, subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and the officers, directors, employees, agents and representatives of each of them harmless, including costs, liabilities and legal fees, from any claim or demand made by any third party due to or arising out of (a) your access to and use of the Site, (b) your violation of the Terms of Use, and (c) the infringement by you, or any third party using your Account, of any intellectual property or other right of any person or entity. Sprintlio reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Sprintlio. Sprintlio will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>

          <h2>15. Warranty Disclaimer and Limitation of Liability</h2>
          <p>15.1. Any use of the Site, reliance upon any of the Site Content, and any use of the Internet generally shall be at your sole risk and Sprintlio does not review, edit or censor search results, Content or Third Party links and information. Sprintlio disclaims any and all responsibility or liability for the accuracy, content, completeness, legality, reliability, or operability or availability of information or the Site Content accessible by use of the Site.</p>
          <p>15.2. THE SITE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. SPRINTLIO DISCLAIMS, TO THE FULLEST EXTENT PERMITTED UNDER LAW, ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, ACCURACY AND PERFORMANCE OF THE SITE. SPRINTLIO DOES NOT WARRANT THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE SITE IS FREE OF VIRUSES, ERRORS, OTHER HARMFUL COMPONENTS, OR WILL BE UNINTERRUPTED.</p>
          <p>15.3. IN NO EVENT SHALL SPRINTLIO BE LIABLE TO ANY USER ON ACCOUNT OF SUCH USER&#39;S USE, MISUSE OR RELIANCE ON THE SITE FOR ANY DAMAGES WHATSOEVER, INCLUDING DIRECT, SPECIAL, PUNITIVE, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR DAMAGES FOR LOSS OF PROFITS, REVENUE, USE, OR DATA WHETHER BROUGHT IN WARRANTY, CONTRACT, INTELLECTUAL PROPERTY INFRINGEMENT, TORT (INCLUDING NEGLIGENCE) OR OTHER THEORY, EVEN IF SPRINTLIO IS AWARE OF OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, ARISING OUT OF OR CONNECTED WITH (A) THE USE (OR INABILITY TO USE) OR PERFORMANCE OF THE SITE, (B) THE SITE CONTENT OR THE INTERNET GENERALLY, (C) THE USE (OR INABILITY TO USE), (D) RELIANCE UPON OR PERFORMANCE OF ANY SITE CONTENT CONTAINED IN OR ACCESSED FROM ANY SPRINTLIO SITE OR A THIRD PARTY SITE, OR (E) ANY PRODUCTS OR SERVICES PURCHASED OR OBTAINED AS A RESULT OF AN ADVERTISEMENT OR OTHER INFORMATION OR MATERIAL ON OR IN CONNECTION WITH THE SITE. SPRINTLIO DOES NOT ASSUME ANY LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS, TIMELINESS OR USEFULNESS OF ANY INFORMATION, APPARATUS, PRODUCT OR PROCESS DISCLOSED ON THE SITE OR OTHER SITE CONTENT ACCESSIBLE FROM THE SITE.</p>
          <p>15.4. THE USER OF THE SITE ASSUMES ALL RESPONSIBILITY AND RISK FOR THE USE OF THIS SITE AND THE INTERNET GENERALLY.</p>
          <p>15.5. NOTWITHSTANDING ANY OTHER PROVISION OF THE TERMS OF USE OR ANY RELATED DOCUMENT, AGREEMENT OR UNDERSTANDING, YOU EXPRESSLY AND IRREVOCABLY AGREE THAT SPRINTLIO’S LIABILITY FOR ANY LOSS OR DAMAGES WHATSOEVER CLAIMED BY YOU OR THROUGH YOU OR IN ANY WAY RELATED TO THE SITE OR THE TERMS OF USE SHALL BE LIMITED IN AGGREGATE TO $100.00 TO THE FULLEST EXTENT PERMITTED UNDER ALL APPLICABLE LAWS.</p>
          <p>15.6. THE LEGAL FEES AND OTHER LEGAL COSTS OF THE PREVAILING PARTY ASSOCIATED WITH THE ENFORCEMENT OF RIGHTS UNDER THE TERMS OF USE SHALL BE PAID BY THE NON-PREVAILING PARTY TO THE PREVAILING PARTY.</p>

          <h2>16. Dispute Resolution and Governing Law</h2>
          <p>
            16.1. The Terms of Use and any claim, cause of action or dispute (“<strong>Claim</strong>”) arising out of or related to shall be governed by and construed and enforced in accordance with the laws of the Province of Ontario regardless of your country of origin or where you access the Site, and notwithstanding of any conflicts of law principles and the United Nations Convention for the International Sale of Goods. You and Sprintlio agree that all claims arising out of or related to the Terms of Use and/or your use of the Site, must be resolved exclusively by a provincial or federal court located in the Greater Toronto Area, except as otherwise mutually agreed by the parties. You and Sprintlio agree to submit to the personal jurisdiction of the courts located within the Greater Toronto Area for the purpose of litigating all such claims. Notwithstanding the above, you agree that Sprintlio shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction of our choosing. All acts involving the registration, use or operation of any Account or of the Site shall be deemed as accessed, entered, perfected or finally accepted at the place of the location of Sprintlio’s headquarters. All acts shall be considered as lastly accepted and perfected by Sprintlio and at Sprintlio’s headquarters.
          </p>

          <h2>17. Miscellaneous</h2>
          <p>
            17.1. <strong>Third Party Sites</strong>. The Site may include links to other websites (each, a “<strong>Third Party Site</strong>”). We do not control or endorse any Third Party Site, and you agree that we are not responsible for the content or the availability of content of such Third Party Sites. The content and information you provided to a Third Party Site may be shared and used on the Site in accordance with our Privacy Policy. Should you not want the content and information you provided to a Third Party Site shared and used on the Site, you should review the Third Party Site’s terms of use and privacy policy and proceed accordingly. In no event shall Sprintlio be liable to you for the use of the content and information provided by a Third Party Site. Sprintlio strongly recommends that you fully read the user agreement and privacy policy of any Third Party Site prior to your use of such Third Party Site.
          </p>
          <p>
            17.2. <strong>Severability</strong>. If any provision of the Terms of Use is found by a court of competent jurisdiction to be illegal, void or unenforceable, the unenforceable provision will be modified so as to render it enforceable and effective to the maximum extent possible in order to affect the intention of the provision. If a court finds the modified provision invalid, illegal, void or unenforceable, the validity, legality and enforceability of the remaining provisions of the Terms of Use will not be affected in any way.
          </p>
          <p>
            17.3. <strong>Language</strong>. Where we may provide you with a translation of the English language version of the Terms of Use or any other documentation, you agree that the translation may be provided solely for your convenience and that the English language versions of the Terms of Use and any other documentation will govern the Terms of Use and your relationship with Sprintlio and the Purpose.
          </p>
          <p>
            17.4. <strong>Notice to Sprintlio</strong>. Any notice to be given to Sprintlio must be given by email at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>. 
          </p>
          <p>
            17.5. <strong>Informed Consent</strong>. You shall not be entitled to argue that you lacked independent legal advice before agreeing to the Terms of Use, on the basis that you failed to consult a lawyer, or on the basis that you chose to employ the lawyer of another party, or even that you chose to rely only on another party retaining a lawyer.
          </p>
          <p>
            17.6. <strong>Headings for Convenience Only</strong>. The division of the Terms of Use into headings, articles and sections is for convenience of reference only and shall not affect the interpretation or construction of the Terms of Use.
          </p>
          <p>
            17.7. <strong>Entire Agreement</strong>. The Terms of Use, inclusive of the Privacy Policy, constitute the complete and entire expression of the Terms of Use and the relationship between you and Sprintlio with respect to the subject matter hereof, and shall supersede any and all other agreements and understandings, whether written or oral, between the parties.
          </p>
          <p>
            17.8. <strong>Amendment</strong>. We reserve the exclusive right to modify, supplement, amend or replace the terms of the Terms of Use, effective as provided for in Section 4.1.
          </p>
          <p>
            17.9. <strong>No Injunctive Relief</strong>. In no event shall you seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation of the Site.
          </p>
          <p>
            17.10. <strong>Beneficiaries</strong>. The members of Sprintlio are intended third party beneficiaries of the Terms of Use, with a right to enforce the Terms of Use directly against you.
          </p>
          <p>
            17.11. <strong>Assignment and Delegation</strong>. You may not assign or delegate any rights or obligations under the Terms of Use. Any purported assignment and delegation shall be ineffective. We may freely assign or delegate, full or partially, all rights and obligations under the Terms of Use without notice to you. We may also substitute, by way of unilateral novation, effective upon notice to you as provided for herein, Sprintlio for any third party that assumes our rights and obligations under the Terms of Use.
          </p>
          <p>
            17.12. <strong>Potential Other Rights and Obligations</strong>. You may have rights or obligations under local law other than those enumerated here if you are located outside Canada.
          </p>
          <p>
            17.13. <strong>Copyright Infringement</strong>. If you believe, in good faith, that any materials on the Site infringe upon your copyrights, please send the following information to Sprintlio in accordance with Section 17.4 or to <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>:
          </p>
          <ul>
            <li>Identify the copyrighted work that you claim has been infringed, including the URL (Internet address) or other specific location on the Site where the material you claim is infringed is located. Include enough information to allow Sprintlio to locate the material, and explain why you think an infringement has taken place. If multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site is allowed;</li>
            <li>Identify the allegedly infringing work and include information reasonably sufficient to allow us to locate the material -- for example, the URL (Internet address) where it is posted or the name of the book in which it has been published;</li>
            <li>Your full legal name, mailing address, telephone number, and e-mail address;</li>
            <li>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
            <li>A statement by you, made under penalty of perjury, that the information in your notice is accurate, and that you are the copyright owner or are authorized to act on the copyright owner&#39;s behalf; and</li>
            <li>An electronic or physical signature of the owner of the copyright or the person authorized to act on behalf of the owner of the copyright interest.</li>
          </ul>
          <p>
            17.14. <strong>Survival</strong>. Any Sections of these Terms of Use which by its nature should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability, shall survive termination and continue in full force and effect except to the extent expressly set out in these Terms of Use.
          </p>
        </DocPage>
      </Inner>
    </Outer>
  </DocPageLayout>
);

export default TermsPage;
